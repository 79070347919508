@import '~antd/dist/antd.less';

.layout {
    height: 100vh;
}

.layout.layout--no-sidebar {
    .ant-layout-content {
        padding: 0;
    }
}

.ant-layout-header {
    padding: 0 20px;
    @media screen and (min-width: 768px) {
        padding: 0 50px;
    }
}

.main-navigation {
    width: 100%;
    right: 0;

    @media screen and (max-width: 768px) {
        left: 0 !important;
    }

    @media screen and (min-width: 768px) {
        width: auto;
    }
}

.ant-layout-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.site-layout-content {
    min-height: 280px;
    padding: 0;
    flex: 1;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 768px) {
        padding: 24px 50px;
    }
}

.logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    display: flex;
}

.page-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 24px;

    img {
        width: 200px;
    }
}

.ant-page-header {
    max-width: 100vw;
}

.message-item-admin {
    height: 60px !important;

    .ant-space-item {
        line-height: 1.2;
    }
}

.message-list {
    .ant-list-items {
        display: flex;
        flex-direction: column;
    }

    .ant-comment-inner {
        padding: 0;
    }
}

.message-item {
    margin-bottom: 8px;
}

.admin-fullscreen-page {
    &.ant-page-header {
        display: flex;
        flex-direction: column;
    }

    .ant-page-header-content {
        flex: 1;
        overflow: hidden;
    }

    .ant-image {
        width: 100%;
    }

    .file-list-container {
        height: auto;

        @media screen and (min-width: 480px) {
            height: 100%;
        }
    }

    .file-list {
        white-space: nowrap;
        max-height: 100px;

        @media screen and (min-width: 480px) {
            white-space: normal;
            height: 100%;
            max-height: 100%;
        }

        .ant-image {
            width: 80px;

            @media screen and (min-width: 480px) {
                width: 100%;
            }
        }
    }
}

.ant-page-header .ant-page-header {
    padding: 0;
}

code {
    font-family: monospace;
}

.video-react .video-react-big-play-button {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.banner-edit-page .ant-upload-list-item-image {
    object-fit: contain;
}

@body-background: #f2f4f8;@font-size-base: 13px;@font-size-lg: 15px;@font-family: Nunito Sans, sans-serif;@code-family: Nunito Sans, sans-serif;@border-color-base: #e4e9f0;@background-color-light: #f2f4f8;@background-color-base: #f2f4f8;@primary-color: #0190fe;@info-color: #0887c9;@success-color: #46be8a;@error-color: #fb434a;@highlight-color: #fb434a;@warning-color: #f39834;@normal-color: #e4e9f0;@input-padding-horizontal: 13px;@input-padding-vertical-base: 7px;@input-padding-vertical-sm: 11px;@input-padding-vertical-lg: 11px;@input-border-color: #e4e9f0;@input-hover-border-color: #0190fe;@shadow-color: rgba(0,0,0,.15);@box-shadow-base: 0 10px 35px -5px rgba(0,0,0,.15);@shadow-1-up: 0 -10px 35px -5px rgba(0,0,0,.15);@shadow-1-down: 0 10px 35px -5px rgba(0,0,0,.15);@shadow-1-left: -10px 10px 35px -5px rgba(0,0,0,.15);@shadow-1-right: 10px 0 35px -5px rgba(0,0,0,.15);@shadow-2: 0 0px 35px -5px rgba(0,0,0,.15);@table-header-bg: #f2f4f8;@table-header-sort-bg: #f2f4f8;@table-row-hover-bg: #e4e9f0;@table-padding-vertical: 12px;@table-padding-horizontal: 8px;@modal-mask-bg: rgba(15, 12, 41, 0.5);